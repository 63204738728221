import { styled, css } from '@creditas/stylitas'
import { Button as CreditasButton } from '@creditas/button'

export default styled(CreditasButton)(
  ({ theme }) => css`
    background: ${theme.colorBackgroundLight};
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colorNeutral90};
    height: 60px;
    border-radius: 16px;

    svg {
      fill: ${theme.colorNeutral90};
    }

    &:hover {
      background-color: ${theme.colorBackgroundDefault};
    }
  `,
)
