import React from 'react'
import { Button } from '@creditas/button'
import { styled } from '@creditas/stylitas'
import { node, func, any } from 'prop-types'

const WebCardButtonStyled = styled(Button)`
  text-align: center;
  min-width: 100%;
  margin-top: 24px;
  text-decoration: none;
`

const propTypes = {
  children: node,
  onClick: func,
  other: any,
}

const WebCardButton = ({ children, onClick, ...other }) => (
  <WebCardButtonStyled onClick={onClick} variant="secondary" {...other}>
    {children}
  </WebCardButtonStyled>
)

WebCardButton.propTypes = propTypes

export { WebCardButton }
