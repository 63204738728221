import React from 'react'
import { styled, css } from '@creditas/stylitas'
import { ActionLink } from '@creditas/button'
import ChevronRightIcon from '@creditas/icons/dist/navigation/ChevronRight'
import { node, string, oneOfType, func, shape, any } from 'prop-types'

const linkWithJustifyContent = ({ justifyContent }) => css`
  justify-content: ${justifyContent};
`

const WebCardLinkStyled = styled(ActionLink)`
  width: 100%;
  padding: 0;
  margin-top: 24px;
  ${linkWithJustifyContent}
`

const propTypes = {
  children: node,
  iconProps: shape({
    icon: oneOfType([node, func]),
  }),
  justifyContent: string,
  href: string,
  other: any,
}

const defaultProps = {
  iconProps: {
    icon: ChevronRightIcon,
  },
  justifyContent: 'space-between',
}

const WebCardLink = ({
  children,
  iconProps,
  justifyContent,
  href,
  ...other
}) => (
  <WebCardLinkStyled
    href={href}
    icon={iconProps.icon}
    justifyContent={justifyContent}
    {...other}
  >
    {children}
  </WebCardLinkStyled>
)

WebCardLink.propTypes = propTypes
WebCardLink.defaultProps = defaultProps

export { WebCardLink }
