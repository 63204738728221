import { func, string, bool, node } from 'prop-types'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import FinalErrorMessage from './FormElements/FinalErrorMessage'
import ThankYou from './FormElements/ThankYou'
import { Container, StyledForm } from './styles'

const propTypes = {
  onSubmit: func,
  submitText: string,
  isSubmitting: bool,
  message: string,
  success: bool,
  successTitle: string,
  children: node,
}

const defaultProps = {
  submitText: 'Enviar',
  isSubmitting: false,
  success: false,
  message: '',
  successTitle: '',
}

const Form = ({
  onSubmit,
  submitText,
  isSubmitting,
  success,
  message,
  successTitle,
  children,
}) => {
  const showFinalErrorMessage = !isSubmitting && !success && message
  const shouldHideForm = isSubmitting || showFinalErrorMessage || success

  return (
    <Container>
      <StyledForm
        data-testid="form"
        onSubmit={onSubmit}
        shouldHideForm={shouldHideForm}
      >
        {children}
        <Button
          data-testid="submitButton"
          className="submitButton"
          fullWidth
          type="submit"
        >
          {submitText}
        </Button>
      </StyledForm>
      {isSubmitting && <Spinner loadingMessage="Loading..." />}
      {showFinalErrorMessage && (
        <FinalErrorMessage
          retrySubmit={onSubmit}
          submitText={submitText}
          message={message}
        />
      )}
      {success && <ThankYou whyTitle={successTitle} message={message} />}
    </Container>
  )
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
