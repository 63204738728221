import { styled, css, mq } from '@creditas/stylitas'
import { typography, media } from '@creditas-lab/style-utilities'
import { Typography } from '@creditas/typography'
import { ButtonCta } from 'components/ButtonCta'
import { WebCardBody } from 'components/Card'
import { fillMediaBase } from 'src/ui/utils/fillMediaBase'

const Wrapper = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    min-height: 180px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${theme.colorNeutralWhite};
    padding: 24px;
    border-radius: 20px;
    box-shadow: 0 0 64px rgba(20, 20, 20, 0.04);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 24px;
      box-shadow: 0 28px 64px rgba(20, 20, 20, 0.08);
      z-index: -1;
    }

    ${mq({
      minHeight: fillMediaBase('152px', '180px'),
    })}
  `,
)

const Title = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorFontNeutral90};
    margin-bottom: 12px;
    ${typography.mobile.titleSM400(theme)}
    font-weight: 700;

    ${media.md`
      margin-bottom: 16px;
      ${typography.desktop.titleXS400(theme)}
      font-weight: 700;
    `}
  `,
)

const Body = styled(WebCardBody)(
  ({ theme }) => css`
    white-space: pre-line;
    color: ${theme.colorFontNeutral60};
    ${typography.mobile.paragraphMD400(theme)}

    ${media.md`
      ${typography.desktop.paragraphMD400(theme)}
    `}
  
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `,
)

const Cta = styled(ButtonCta)(
  ({ theme }) => css`
    margin-top: auto;
    border: 1px solid transparent;
    text-decoration: none;
    color: ${theme.colorBrandPrimaryDefault};
    ${typography.desktop.paragraphMD400(theme)}

    &:hover {
      color: ${theme.colorBrandPrimaryDefault};
      text-decoration: underline;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    svg {
      color: ${theme.colorBrandPrimaryDefault};
      display: block;
      margin-left: 8px;
    }
  `,
)

export { Wrapper, Title, Body, Cta }
