import { css, styled } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { Typography } from '@creditas/typography'

const Container = styled.div`
  width: 100%;
`
const Title = styled(Typography)(
  ({ theme }) => css`
    ${typography.desktop.titleLG300(theme)}

    font-weight: bold;
  `,
)

const Subtitle = styled(Typography)(
  ({ theme }) => css`
    margin-top: 8px;
    font-weight: normal;

    letter-spacing: 0.5px;
    color: ${theme.colorNeutral90};
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    margin-top: 16px;
    margin-bottom: 40px;

    ${typography.desktop.paragraphSM400(theme)}
  `,
)

export { Container, Title, Subtitle, Description }
