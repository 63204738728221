import { css, styled } from '@creditas/stylitas'
import { typography } from '@creditas-lab/style-utilities'
import { Typography } from '@creditas/typography'

const Container = styled.div`
  width: 100%;
`

const Title = styled(Typography)(
  ({ theme }) => css`
    ${typography.desktop.titleLG300(theme)}
    strong {
      font-weight: 700;
    }
  `,
)

const WhyTitle = styled(Typography)(
  ({ theme }) => css`
    ${typography.desktop.titleLG300(theme)}
  `,
)

const Description = styled(Typography)`
  margin-top: 12px;
`

export { Container, Title, WhyTitle, Description }
