import React from 'react'
import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { node } from 'prop-types'

const propTypes = {
  children: node,
}

const WebCardTitleSocialProofStyled = styled(Typography)`
  width: 32px;
  height: 42px;
  margin-bottom: 13px;
  ${({ theme }) => `color: ${theme.bColorprimary03}`}
`

const WebCardTitleSocialProof = ({ children, ...others }) => (
  <WebCardTitleSocialProofStyled {...others} variant="h1" as="span">
    {children}
  </WebCardTitleSocialProofStyled>
)

WebCardTitleSocialProof.propTypes = propTypes

export { WebCardTitleSocialProof }
